// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-career-page-career-page-js": () => import("./../../../src/components/CareerPage/CareerPage.js" /* webpackChunkName: "component---src-components-career-page-career-page-js" */),
  "component---src-components-project-details-project-details-js": () => import("./../../../src/components/ProjectDetails/ProjectDetails.js" /* webpackChunkName: "component---src-components-project-details-project-details-js" */),
  "component---src-components-vacancy-page-vacancy-page-js": () => import("./../../../src/components/VacancyPage/VacancyPage.js" /* webpackChunkName: "component---src-components-vacancy-page-vacancy-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-js": () => import("./../../../src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-dedicated-developers-index-js": () => import("./../../../src/pages/services/dedicated-developers/index.js" /* webpackChunkName: "component---src-pages-services-dedicated-developers-index-js" */),
  "component---src-pages-services-mobile-development-index-js": () => import("./../../../src/pages/services/mobile-development/index.js" /* webpackChunkName: "component---src-pages-services-mobile-development-index-js" */),
  "component---src-pages-services-solutions-development-index-js": () => import("./../../../src/pages/services/solutions-development/index.js" /* webpackChunkName: "component---src-pages-services-solutions-development-index-js" */),
  "component---src-pages-services-web-development-index-js": () => import("./../../../src/pages/services/web-development/index.js" /* webpackChunkName: "component---src-pages-services-web-development-index-js" */)
}

